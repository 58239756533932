import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading, fetchTestModelList } from '../../../features/settings';
import {
  useTranslation, showError, showWarning, groupById,
  toFloat, download, ucfirst, getRunConfigOption,
  filterKeys, mapObject, mapArrayToObject, downloadData,
} from "../../../core/utils";
import { setMessageState } from "../../../features/messageInfo";
import {
  getDataNew as getData, postDataNew as postData,
  putDataNew as putData, fetchAll,
} from "../../../core/fetchService";
import { MESSAGE_STATUS, DIALOG_USER_STATE, BTN } from "../../../core/constants";
import { ShowMatrix } from "../tests/matrix";
import { makeStyles } from "@material-ui/core/styles";
import UpdateIcon from "@material-ui/icons/Update";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions"
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EnhancedTable from "../../components/projectTable";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmDialog from '../../components/confirmDialog'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "../../components/material-ui/IconButton";
import MultiSelect from "../../components/material-ui/MultiSelect";
import Autocomplete from "../../components/material-ui/Autocomplete";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

import DiffMatchPatch from "diff-match-patch"

export const DIFF_VIEW_ID = 'diff';

const ASR_KEY_MARK = 'x'; // Warning: same as in FE

const C_WHITE = "white";
const C_GREY =  "#eeeeee";
const C_RED =   "#ffdddd";
const C_GREEN = "#dfffdd";

const useStyles = makeStyles(theme => ({
  applyIconHide: {
    visibility: 'hidden',
  },
  compareEqual: {
    backgroundColor: C_GREEN,
  },
  compareNotEqual: {
    backgroundColor: C_RED,
    '&:hover .applyIconShow': {
      visibility: 'visible',
    }
  },
  compareOldNewEqual: {
    color: "grey",
  },
  diffRemoved: {
    color: 'green',
    fontWeight: 'bold',
    textDecoration: 'line-through',
  },
  diffAdded: {
    color: 'red',
    fontWeight: 'bold',
  },
  asrGrey: {
    backgroundColor: "#eee",
  },
  customInputPadding: {
    '& .MuiOutlinedInput-input': {
      padding: '5px 0',
      '&:focus': {
        paddingLeft: 5,
      },
      '&:hover': {
        paddingLeft: 5,
      },
    },
  }
}));

export function testModelCompareName(models, tm, details, show_model_name = true) {
  const getNLUModel = tm => models.find(m => m._id === tm?.model);
  const model_name = show_model_name ? ` // ${getNLUModel(tm)?.name}` : '';
  return `${tm?.name}${model_name}`+(details ? ' >> ' + getTestModelIntentAcc(tm) : '');
};

export function getTestModelIntentAcc(tm) { return tm?.data?.int && !tm?.data?.asr && toFloat(tm.intent, 4) }
export function getTestModelSlotAcc(tm)   { return tm?.data?.ent && toFloat(tm.entity, 4) }
export function getTestModelWRR(tm)       { return typeof tm?.data?.asr == 'string' && tm.data.asr.split('\n')[1].split('\t')[0] }

export const VIEW_TYPES = { int: 'intents', ent: 'slots', cm: 'confusion', asr: 'asr'};

export default function ReportDialog(props) {
  const {
    testModelId, testModel2Id, showEntityTab, intentsList, slotsList,
    testModelList, onClose, testSetChanged,
  } = props;

  const TABS = Object.keys(VIEW_TYPES);
  const NO_COMPARE = ""; // empty value of a controlled input

  const DMP = new DiffMatchPatch();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const testmodel_1 = testModelList?.testmodels.find(m => m._id == testModelId);
  const dataset = testModelList?.datasets.find(d => d._id == testmodel_1.dataset);
  const intents = groupById(intentsList || []);

  const is_asr = testModelList?.models.find(m => m._id == testmodel_1.model)?.type == 'ASR_E2E';

  const [viewTab,         setViewTab] =         useState(is_asr ? "asr" : showEntityTab ? "ent" : "int");
  const [data,            setData] =            useState(testmodel_1?.data[viewTab]);
  const [comparingModel,  setComparingModel] =  useState(testModel2Id || NO_COMPARE);
  const [showDiff,        setShowDiff] =        useState(false);
  const [rows,            setRows] =            useState([]);
  const [paging,          setPaging] =          useState(null);
  const [pagingListInfo,  setPagingListInfo] =  useState(null);
  const [_knownIntents,   setKnownIntents] =    useState({});
  const [knownSlots,      setKnownSlots] =      useState({});
  const [spareSlotValues, setSpareSlotValues] = useState({});
  const [diffMode,        setDiffMode] =        useState(26);

  const showDiffOnly = diffMode != 26;

  /* This state is used to avoid a visible intermediate change in table when switching on/off the new-vs-old
   * diff mode (comparingModel); this intermediate change corresponds to the state (an intermediate render of
   * ReportDialog) when comparingModel has already changed but fetchRows invoked in useEffect(..., [comparingModel]) has not
   * yet been called/finished
   */
  const [dvcip, setDiffViewChangeInProgress] =  useState(false);

  const scroll = "paper";
  const testmodel_2 = testModelList?.testmodels.find(m => m._id === comparingModel);
  const comparingData = testmodel_2?.data?.[viewTab];

  const comparableModels = testModelList?.testmodels.filter(tm => (
    tm.dataset == testmodel_1.dataset && tm._id != testModelId
    && (showDiff ? viewTab != 'int' || tm.result?.length : tm.data?.[viewTab])
    && (viewTab != 'asr' || tm.data?.report?.data)
  ));

  const testCompareName = testModelCompareName.bind(null, testModelList?.models);

  const addTestModelResultIntents = (ints, tm) =>
    (tm.result || []).reduce((a,i) => { a[tm.labels[i]] = 1; return a }, {...ints});

  useEffect(() => {
    if (is_asr)
      return;
    getData(`/api/dataset/${dataset._id}/stat`, dispatch, data => {
      const refIntents = data.intents.reduce((a,i) => { a[intents[i].name] = 1; return a }, {});
      setKnownIntents(addTestModelResultIntents(refIntents, testmodel_1));
    });
  }, []);

  const knownIntents = testmodel_2 ? addTestModelResultIntents(_knownIntents, testmodel_2) : _knownIntents;
  const getTitle = i => i?.name + (i?.description ? ' // '+ i.description : ''); // as in annotations.js (CustomAutocomplete)
  const refIntentsAll = intentsList
    ?.map(intent => ({ value: intent._id, label: intent.name, title: getTitle(intent) }));
  const refIntents = refIntentsAll?.filter(intent => knownIntents[intent.label]);
  const testmodelIntents = tm => {
    if (!tm)
      return null;
    const tmi = addTestModelResultIntents({}, tm);
    return refIntentsAll?.filter(intent => tmi[intent.label])
      ?.map?.(intent => ({ ...intent, value: intent.label }));
  };
  const refSlotsAll = slotsList
    ?.map(slot => ({ value: slot.name, label: slot.name, title: `Number of entities: `+ slot.entityIds.length }));
  const refSlots = refSlotsAll?.filter(s => knownSlots[s.label]);
  const testmodelSlots = tm => {
    if (!tm)
      return null;
    return [...new Set((tm.data?.ent?.errors || []).map(e => e.predicted_entities.map(s => s.entity)).flat())]
      .map(name => ({ value: name, label: name }));
  };
  const _TA = TABS.reduce((a,k) => ({ ...a, [k]: [] }), {});
  const [tm1Intents, tm2Intents] = { ..._TA,  int: [testmodel_1, testmodel_2].map(testmodelIntents) }[viewTab];
  const [tm1Slots,   tm2Slots] =   { ..._TA,  ent: [testmodel_1, testmodel_2].map(testmodelSlots)   }[viewTab];

  const handleChange = (event, newValue) => {
    const viewTab = TABS[newValue];
    if (viewTab != 'cm')
      setData(testmodel_1.data[viewTab]);
    setViewTab(viewTab);
  };

  const exportConfMatrixAsCSV = () => download(`/api/testmodel/${testModelId}/cm`, dispatch);

  const ComparingModelSelect = () => {
    return (
      <FormControl size="small">
        <InputLabel id={`${t("common.compare")}_label`}>{t("common.compare")}</InputLabel>
        <Select
          style={{ alignSelf: "start", width: 200, fontSize: 14 }}
          id="step"
          labelId={`${t("common.compare")}_label`}
          value={comparingModel}
          disabled={!comparableModels.length && !testmodel_2}
          label={t("common.compare")}
          onChange={event => {
            const cmp_model = event.target.value;
            if (showDiff)
              setDiffMode(cmp_model ? 24 : 26);
            setDiffViewChangeInProgress(showDiffOnly && Boolean(comparingModel) != Boolean(event.target.value));
            setComparingModel(cmp_model);
            setPaging({ ...paging, page: 1 });
          }}
        >
          {comparingModel &&
          <MenuItem style={{ fontSize: 14 }} key={"null"} value={NO_COMPARE}>
            <span style={{textAlign:'center'}}>
              {'--------- '+ t("common.reset") +' ---------'}
            </span>
            {/* <hr style={{width: "100%"}}/> */}
          </MenuItem>}
          {comparableModels.map(tm => tm.data &&
            <MenuItem style={{ fontSize: 14 }} key={tm._id} value={tm._id}>
              {testCompareName(tm, true)}
            </MenuItem>)}
        </Select>
      </FormControl>
    )
  };

  const METRICS = { precision: 'P', f1_score: 'F1', accuracy: 'A' };
  const KEYS = Object.keys(METRICS);

  const cell = (d1, d2, m=false, p=4, s=true) => k => {
    const get_color = () => d2 && d2[k]
      ? (d1[k] == d2[k] ? C_WHITE : d1[k] > d2[k] ? C_GREEN : C_RED)
      : C_WHITE;
    return [
      (m ? METRICS[k] : t("train."+ k)) +': ',
      s ? <span key={k} style={{background: get_color()}}>
        {toFloat(d1[k], p)}
      </span> : {
        tag: 'span',
        background: get_color(),
        data: toFloat(d1[k], p),
      },
      ...(k==KEYS[KEYS.length-1] ? [] : [', '])
    ]};

  const NO_VALUE = '';

  const prepareReports = () => {
    const [NAME, SUPPORT] = [0,4];
    if (!data?.report)
      return [];
    const header_size = d => !d?.meta || d.meta.header ? 1 : 0;
    const bh = header_size(data);
    const ch = header_size(comparingData);
    if (bh != ch) {
      console.error(`Incompatible report header sizes: ${bh} != ${ch}`);
      return [];
    }
    const baseReport = data.report.slice(bh); // strip header
    const comparingReport = (comparingData?.report || []).slice(ch);
    const order = baseReport.reduce((a,row,i) => { a[row[NAME]] = [i,row[SUPPORT]]; return a }, {});
    const X = NO_VALUE;
    let i = baseReport.length;
    const comparingIntents = {};
    comparingReport.forEach(row => {
      const name = row[NAME];
      if (!order[name]) {
        baseReport.push([name, X, X, X, row[SUPPORT]]);
        order[name] = [i++, 0];
      };
      comparingIntents[name] = 1;
    });
    Object.keys(order).forEach(name => {
      if (!comparingIntents[name])
        comparingReport.push([name, X, X, X, order[name][1]]);
    });
    comparingReport.sort((a,b) => order[a[NAME]][0] - order[b[NAME]][0]);
    return [
      [].concat(data.report.slice(0, bh), baseReport),
      comparingData?.report
      && [].concat(comparingData.report.slice(0, ch), comparingReport)
    ];
  };
  const [baseReport, comparingReport] = prepareReports();
  const summary = is_csv => {
    if (viewTab == 'asr')
      return show_asr_stat(is_csv);
    if (!baseReport) return null;
    function _TC(props, data){
        this.props = props;
        this.data = data || '';
    }
    const _TableCell = (...args) => new _TC(...args);
    const head = [[
      _TableCell({}),
      _TableCell({ align: "center", colSpan: 4 }, testCompareName(testmodel_1)),
      ...(is_csv ? Array.from({length: 3}, () => _TableCell({})) : []),
      comparingReport
      ? _TableCell( { align: "center", colSpan: 4 }, testCompareName(testmodel_2))
      : null,
    ], is_csv ? [] : [
      _TableCell({}),
      _TableCell({ align: "center", colSpan: 4 }, KEYS.map(cell(data, comparingData, undefined, undefined, !is_csv))),
      comparingReport
      ? _TableCell({ align: "center", colSpan: 4 }, KEYS.map(cell(comparingData, data, undefined, undefined, !is_csv)))
      : null,
    ], [
      _TableCell({}, viewTab == "int" ? t("tests.intent_name") : t("tests.slot_name")),
      ...(['precision', 'recall', 'f1_score', 'support']
          .map(k => _TableCell({ align: "right", key: "1_"+ k }, t("train."+ k)))),
      ...(comparingReport ? ['precision', 'recall', 'f1_score', 'support']
          .map(k => _TableCell({ align: "right", key: "2_"+ k }, t("train."+ k))) : []),
    ]];
    const body = baseReport.map((row, i) => {
      if (i == 0)
        return null;
      const style = (j, sign) => j==4 ? {} : {
        background:
          comparingReport?.[i] && comparingReport[i][j] != row[j]
          ? (row[j] === NO_VALUE ? (sign > 0 ? C_GREY : C_WHITE)
            : comparingReport?.[i]?.[j] === NO_VALUE ? (sign < 0 ? C_GREY : C_WHITE)
            : (comparingReport[i][j] - row[j]) * sign < 0 ? C_GREEN : C_RED)
          : C_WHITE
      };
      const PRFColumns = sign =>
        [1,2,3,4].map(j =>
                      _TableCell({ style: style(j, sign), align: "right", key: String(j)+sign },
                                 sign > 0 ? row[j] : comparingReport[i][j]));
      return [
        _TableCell({ component: "th", scope: "row" }, row[0]),
        ...PRFColumns(1),
        ...(comparingModel && comparingReport?.[i]
            ? PRFColumns(-1) : [])
      ];
    });
    const genRows = rows => rows.map((r,i) => r && (
      <TableRow key={i}>
        {r.map((c,j) => c && (
          <TableCell key={i+'_'+j} {...c.props}>
            {c.data}
          </TableCell>
        ))}
      </TableRow>
    ));
    const genCSVRows = rows => rows.map((r,i) => r && (
        r.map((c,j) => c && (
          ['string', 'number'].includes(typeof c.data) ? c.data
            : c.data.flat().map(s => typeof s == 'string' ? s : s.data).join(' ')
        )).join('\t')
    )).join('\n');
    return is_csv ? [genCSVRows(head), '', genCSVRows(body)].join('\n') : (
      <TableContainer style={{ height: "96%" }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            {genRows(head)}
          </TableHead>
          <TableBody>
            {genRows(body)}
          </TableBody>
        </Table>
      </TableContainer>
    )};

  const fetchRows = (new_paging, filename) => {
    if (new_paging) {
      new_paging.dataset = testmodel_1.dataset;
      if (viewTab == 'int') {
        (new_paging.filters || []).forEach(f => {
          if (f[0] == 'ref')
            f[0] = 'intentId';
        });
        if (new_paging.sort.ref) {
          new_paging.sort.intentId = new_paging.sort.ref;
          delete new_paging.sort.ref;
        }
      }
      setPaging(new_paging);
    }
    const _paging = new_paging || paging;

    _paging.view = { int: 'intents', ent: 'slots', asr: 'asr' }[viewTab];
    if (viewTab == 'asr' && !getRunConfigOption('useCommonDiffForASR'))
      _paging.asr_diff_2 = true;
    _paging.apply_model_filters = testmodel_1.model;
    _paging.diff_mode = showDiffOnly ? diffMode : undefined;
    _paging.diff_testmodel_1 = testmodel_1._id;
    if (testmodel_2)
      _paging.diff_testmodel_2 = testmodel_2._id;

    if (filename) // == is download
      return download('/api/datasetrow', dispatch, filename, { ..._paging, download: true });

    return postData('/api/datasetrow', _paging, dispatch, data => {
      const { docs, removedSlots, refSlots, refSlotValues, ...pagingListInfo } = data;
      if (viewTab == 'ent') {
        if (Object.keys(removedSlots).length && !getRunConfigOption('noWarningDiffRemovedSlots')) {
          const removed = mapObject(removedSlots, sas =>
            sas.map(sa => `[${sa.start},${sa.end}],${sa.slot_name}=${sa.annotation.map(v => v.value).join(';')}`));
          showWarning(dispatch, t)('tests.diff_removed_slots', JSON.stringify(removed));
        }
        setKnownSlots(mapArrayToObject(refSlots));
        setSpareSlotValues(refSlotValues);
      }
      setPagingListInfo(pagingListInfo);
      setRows(docs);
      setDiffViewChangeInProgress(false);
    });
  };

  const fetchTestModels = () =>
    dispatch(fetchTestModelList({ projectId: testmodel_1.project }));

  /** in showDiffOnly mode fetched rows are different for each comparingModel
   */
  useEffect(() => {
    showDiff && paging && (showDiffOnly ? fetchRows() : setRows(rows));
  }, [comparingModel]);

  /** when diffMode changes fetched rows change too
   */
  useEffect(() => {
    paging && fetchRows({...paging, page: 1 });
  }, [diffMode]);

  const handleIntentsRowItemChanged = row => {
    const { old, new:n } = row;
    putData(`/api/dataset/${testmodel_1.dataset}/row/${row._id}`, {
      row: {
        intentId: row.ref,
      }
    }, dispatch, data => {
      setRows(rows.map(r => r._id == row._id ? {
        ...data.value,
        ref: data.value.intentId,
        old,
        new:n,
      } : r));

      fetchTestModels();
    });
  };

  let _headCells = null;

  const handleSlotsRowItemChanged = row => {
    const { rowName, _id, bounds, synonym, ref_slot, ref_value, sub_id } = row;
    putData(`/api/datasetrow/${_id}/ref_slot`, {
      row: {
        _id,
        start: +bounds.split(',')[0],
        end:   +bounds.split(',')[1],
        synonym,
        ref_slot,
        ref_value: rowName == 'ref_value' ? ref_value : null,
        sub_id,
      },
    }, dispatch, data => {
      setRows(rows.map(r => r._id == _id && r.sub_id  == sub_id ? ({
        ...filterKeys(row, _headCells.map(c => c._id)), // avoid passing __span, __classes, etc
        ...data.value,
      }) : r));

      fetchTestModels();
    });
  };

  const handleRowItemChanged = row => {
    if (!['ref', 'ref_slot', 'ref_value'].includes(row.rowName))
      return;
    ({ int: handleIntentsRowItemChanged, ent: handleSlotsRowItemChanged })[viewTab](row);
  };

  const updateMany = (cell_id, tm, setShowConfirm) => one_page => modalState => {
    setShowConfirm(false);
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (one_page) {
      const errors = [];
      let no_diff = true;
      const mod_rows = rows.map(row => {
        const intent = refIntents.find(i => i.label == row[cell_id]);
        no_diff = no_diff && row.ref == intent.value;
        return intent ? { ...row, ref: intent.value } : (errors.push(row), row);
      });
      if (no_diff)
        return showError(dispatch, t)('tests.diff_no_diffs_on_page');
      if (errors.length)
        return showError(dispatch, t)('tests.diff_no_intents', { names: errors.map(row => row[cell_id]).join(', ') });
      const idx = rows.reduce((a,row) => { a[row._id] = row; return a }, {});
      fetchAll(mod_rows.map(row =>
        putData(`/api/dataset/${testmodel_1.dataset}/row/${row._id}`, {
          row: {
            intentId: row.ref,
          }
        }, dispatch)
      )).then(data_all => {
        setRows(data_all.map(data => {
          const r = data.value;
          return {
            ...r,
            ref: r.intentId,
            old: idx[r._id].old,
            new: idx[r._id].new,
          };
        }));
        fetchTestModels();
      });
    } else {
      postData(`/api/testmodel/${tm._id}/set_as_ref`, {}, dispatch, data => {
        fetchRows();
        fetchTestModels();
      });
    }
  };

  const exportName = (mode, ext='csv') => VIEW_TYPES[viewTab] +'_'
    +mode +'_'+(testmodel_2 ? 'diff_' : '')+ testCompareName(testmodel_1, false, false)
    +(testmodel_2 ? '__'+ testCompareName(testmodel_2, false, false) : '')+'.'+ ext;

  const DIFF_MODE_PARAMS = [
    { _id: 0, name: 'ref<>t1' },
    { _id: 1, name: 'ref<>t2' },
    { _id: 2, name:  't1<>t2' },
  ];
  const DIFF_MODES = [
    { key: 26, pattern: "--- any ---", /* "aaa,aab,aba,abb,abc" */ },
    { key:  0, pattern: "abc" },
    { key:  1, pattern: "abb" },
    { key:  2, pattern: "abb,abc" },
    { key:  3, pattern: "aba" },
    { key:  6, pattern: "aba,abc" },
    { key:  8, pattern: "aba,abb,abc" },
    { key:  9, pattern: "aab" },
    { key: 14, pattern: "aaa" },
    { key: 17, pattern: "aaa,aab" },
    { key: 18, pattern: "aab,abc" },
    { key: 20, pattern: "aab,abc,abb" },
    { key: 23, pattern: "aaa,aba" },
    { key: 24, pattern: "aab,aba,abc" },
    { key: 25, pattern: "aaa,abb" },
  ];
  const n2d = n0 => {
    const split3 = n => { const x = Math.floor(n/3); return [x, n-3*x] };
    const [n1,d1] = split3(n0);
    const [n2,d2] = split3(n1);
    const [n3,d3] = split3(n2);
    // console.log('DDDDDDD n2d:',`${n0} => ${d3}${d2}${d1}`);
    return [d3,d2,d1];
  };
  const d2n = digits => digits.reduce((a,d,i) => a + 3**(digits.length-1-i)*d, 0);
  const diffModeToParams = () => DIFF_MODE_PARAMS.filter(p => !n2d(diffMode)[p._id]);
  const paramsToDiffMode = params => d2n(DIFF_MODE_PARAMS.map(p => params.includes(p) ? 0 : 2));

  const diffViewCustomHeader = () => showDiff ? (
    <div style={{display: 'flex'}}>{/* w/o div overflows from outer box */}
      {comparingModel
        ? <FormControl size="small">
          {getRunConfigOption("diffModes")
            ? <Autocomplete
              id="diffModes"
              style={{ width: 340, marginRight: 20, fontSize: 14 }}
              options={DIFF_MODES}
              onChange={(event, value) => setDiffMode(value?.key || 26)}
              getOptionLabel={option => option.pattern}
              getOptionSelected={(option, value) => option.key == value.key}
              value={DIFF_MODES.find(d => d.key == diffMode)}
              label={t("tests.diff_modes")}
            />
            : 0 ? <MultiSelect
              id="diffModeParams"
              style={{ width : 340, marginRight: 20, fontSize: 14 }}
              options={DIFF_MODE_PARAMS}
              onChange={(event, value) => setDiffMode(paramsToDiffMode(value))}
              getOptionLabel={option => option.name}
              value={diffModeToParams()}
              label={t("tests.diff_modes")}
            />
            : <FormControlLabel
              style={{ width: 170 }}
              control={<Checkbox/>}
              label={t("tests.diff_show_only_diffs")}
              checked={diffMode == 24} // see DIFF_MODES
              onChange={e => setDiffMode(e.target.checked ? 24 : 26)}
            />}
        </FormControl>
        : viewTab == 'ent' ? null
        : <FormControlLabel
          style={{ width: 170 }}
          control={<Checkbox/>}
          label={t("tests.diff_show_only_misses")}
          checked={diffMode == 8} // see DIFF_MODES
          onChange={e => setDiffMode(e.target.checked ? 8 : 26)}
        />}
      {ComparingModelSelect()}
      <IconButton
        Icon={GetAppIcon}
        title="csv" // ?? | This goes to a separate wrapping <span> instead of going directly to the <button>
                    // N1 | representing the <IconButton>; following that it required an additional marginTop:7
                    //    | adjustment (see below) to align the button properly (comparing to using MuiIconButton)
        style={{marginTop: 7, marginLeft: 20}}
        onClick={() => fetchRows(paging, exportName('details'))}
      />
    </div>
  ) : null;

  const _diff = () => {
    if (viewTab == 'asr' && !getRunConfigOption('useCommonDiffForASR'))
      return asr_diff();
    if (!baseReport && viewTab != 'asr')
      return null;
    const textCell = {
      align: "left",
    };
    const TestLabel = props => {
      const { cell_id, tm, tm2 } = props;
      const [clicked, setClicked] = useState(false);
      const [showConfirm, setShowConfirm] = useState(false);
      const data = tm.data[viewTab];
      const data2 = tm2 && tm2.data[viewTab];
      useEffect(() => { !tm.outdated && setClicked(false) }, [tm.outdated]);
      const onRefreshClick = e => { e.stopPropagation(); setClicked(true); testSetChanged(tm, () => setClicked(false)) };
      return <>
        {showConfirm && <SetAllAsRefDialog onClose={updateMany(cell_id, tm, setShowConfirm)}/>}
        <div style={{display: 'flex', background: 'unset'}} className={classes.compareNotEqual}>
          <div style={{flex: '1 1 100%'}}>
            <div style={{textAlign: 'center'}}>
              {testCompareName(tm, false, false)}
            </div>
            <div style={{display: 'flex'}}>
              <div style={{flex: '1 1 100%'}}>
                <Tooltip title={KEYS.map(k => <div key={k}>{t('train.'+ k) +': '+ toFloat(data[k],4)}</div>)}>
                  <div style={{visibility: ['hidden','visible'][+!clicked] }}>
                    {KEYS.map(cell(data, data2, true, 3))}
                  </div>
                </Tooltip>
              </div>
              {viewTab != 'ent' &&
                <IconButton
                  Icon={clicked ? CircularProgress : UpdateIcon}
                  title={t("common.refresh")}
                  style={{display: 'block', marginTop: -4, marginLeft: 5, visibility: ['hidden','visible'][+!!(tm.outdated)] }}
                  onClick={onRefreshClick}
                  {...(clicked ? { iconSize: "21px" } : {})}
                />}
            </div>
          </div>
          {getRunConfigOption('enableDiffSetAllAsRef') && 
            <IconButton
              Icon={DoneAllOutlinedIcon}
              title={t(`tests.diff_set_all_as_ref`)}
              className={classes.applyIconHide +' applyIconShow'}
              style={{marginRight: -10}}
              fontSize="small"
              onClick={() => setShowConfirm(true)}
            />}
        </div>
      </>
    };
    const headCellsIntents = [
      {
        _id: "rowNo",
        label: "#",
        width: "5%",
      },
      {
        _id: "text",
        label: t("annotation.text"),
        width: comparingModel ? "35%" : "50%",
        ...textCell,
        textSearch: true,
      },
      {
        _id: "ref",
        label: t('tests.diff_ref'),
        width: "20%",
        title: t('tests.diff_dataset')+ ': '+ dataset.name,
        comboData: refIntentsAll,
        anotherPopper: true,
        filterOn: true,
        filterComboData: refIntents,
        sortable: true,
      },
      {
        _id: "new",
        label: <TestLabel cell_id="new" tm={testmodel_1} tm2={testmodel_2}/>,
        ...textCell,
        filterOn: true,
        filterComboData: tm1Intents,
        sortable: true,
      },
      ...(testmodel_2 && !dvcip ? [{
        _id: "old",
        label: <TestLabel cell_id="old" tm={testmodel_2} tm2={testmodel_1}/>,
        ...textCell,
        filterOn: true,
        filterComboData: tm2Intents,
        sortable: true,
      }] : []),
    ];
    const headCellsSlots = [
      {
        _id: "rowNo",
        label: "#",
        width: "5%",
        mergeEqualRows: true,
      },
      {
        _id: "text",
        label: t("annotation.text"),
        width: comparingModel ? "15%" : "30%",
        ...textCell,
        ...(getRunConfigOption('diffSlot.unpackToRow') ? { unpackToRow: {} } : {}),
        mergeEqualRows: true,
        textSearch: true,
        limit: 100,
      },
      {
        _id: "synonym",
        label: t("annotation.synonym"),
        width: "5%",
        ...textCell,
        textSearch: true,
      },
      {
        _id: "bounds",
        label: t("tests.diff_slot_bounds"),
        ...textCell,
      },
      {
        _id: "ref_slot",
        label: t('tests.diff_slot_ref'),
        width: "10%",
        title: t('tests.diff_dataset')+ ': '+ dataset.name,
        comboData: refSlotsAll,
        filterOn: true,
        filterComboData: refSlots,
        sortable: true,
      },
      {
        _id: "ref_value",
        label: t('tests.diff_slot_value'),
        width: "10%",
        classes: { customTextField: classes.customInputPadding },
        // title: t('tests.diff_dataset')+ ': '+ dataset.name, - incompatible w/textSearch
        // comboData: refSlotsAll,
        textSearch: true,
        // filterOn: true,
        // filterComboData: refSlots,
        sortable: true,
        limit: 12,
      },
      {
        _id: "new_slot",
        label: <TestLabel cell_id="new" tm={testmodel_1} tm2={testmodel_2}/>,
        ...textCell,
        filterOn: true,
        filterComboData: tm1Slots,
        sortable: true,
      },
      {
        _id: "new_value",
        label: testmodel_1.name +' '+ t('tests.diff_slot_value'),
        ...textCell,
        textSearch: true,
        // filterOn: true,
        // filterComboData: tm1Slots,
        sortable: true,
        limit: 12,
      },
      ...(testmodel_2 && !dvcip ? [{
        _id: "old_slot",
        label: <TestLabel cell_id="old" tm={testmodel_2} tm2={testmodel_1}/>,
        ...textCell,
        filterOn: true,
        filterComboData: tm2Slots,
        sortable: true,
      }, {
        _id: "old_value",
        label: testmodel_2.name +' '+ t('tests.diff_slot_value'),
        ...textCell,
        textSearch: true,
        // filterOn: true,
        // filterComboData: tm2Slots,
        sortable: true,
        limit: 12,
      }] : []),
    ];
    const headCellsASR = [
      {
        _id: "rowNo",
        label: "#",
        width: "5%",
      },
      {
        _id: "audio",
        label: t("annotation.audio"),
        width: comparingModel ? "35%" : "50%",
        ...textCell,
      },
      {
        _id: "ref",
        label: t('tests.diff_text_ref'),
        width: "20%",
        // title: t('tests.diff_dataset')+ ': '+ dataset.name, // FIXME: incompatible w/textSearch
        textSearch: true,
        sortable: true,
      },
      {
        _id: "new",
        label: testCompareName(testmodel_1, false, false),
        ...textCell,
        textSearch: true,
        sortable: true,
      },
      ...(testmodel_2 && !dvcip ? [{
        _id: "old",
        label: testCompareName(testmodel_2, false, false),
        ...textCell,
        textSearch: true,
        sortable: true,
      }] : []),
    ];
    const unpackToRow = headCellsSlots.find(c => c._id == 'text').unpackToRow; // no more then one!
    if (unpackToRow)
      unpackToRow.columns = headCellsSlots.map(c => c._id).filter(k => k != 'text');
    const headCells = { int: headCellsIntents, ent: headCellsSlots, asr: headCellsASR}[viewTab];
    _headCells = headCells;
    const viewCellDiff = (row, cell) => (row?.__diffs?.[cell._id] || [[0, row[cell._id]]])
      .map(([change, part], i) => [
        <span key={i +'.0'} className={classes.diffRemoved}>{part}</span>,
        part,
        <span key={i +'.2'} className={classes.diffAdded}>{part}</span>,
      ][change+1]);
    const viewValue = applyValue => (row, cell) => {
      return (
        <div style={{display: 'flex'}} {...(viewTab == 'asr' ? { title: row[cell._id] } : {})}>
          <span style={{flex: '1 1 100%'}}>
            {viewTab == 'asr' ? viewCellDiff(row, cell) : row[cell._id]}
          </span>
          {viewTab != 'asr' ?
            <IconButton
              Icon={CheckOutlinedIcon}
              title={t(`tests.diff_set_as_ref`)}
              onClick={applyValue(row,cell)}
              className={classes.applyIconHide +' applyIconShow'}
              style={{marginRight: -10}}
              fontSize="small"
            /> : null}
        </div>
      );
    };
    const viewIntent = viewValue((row, cell) => () => {
      const intent = refIntentsAll.find(i => i.label == row[cell._id]);
      if (!intent)
        return showError(dispatch, t)('tests.diff_no_intent', {name: row[cell._id]})
      row.ref = intent.value;
      row.rowName = 'ref';
      handleRowItemChanged(row);
    });
    const viewSlot = viewValue((row, cell) => () => {
      row.ref_slot = row[cell._id];
      row.rowName = 'ref_slot';
      handleRowItemChanged(row);
    });
    const viewSlotValue = viewValue((row, cell) => () => {
      row.ref_slot = row[{old_value: 'old_slot', new_value: 'new_slot'}[cell._id]];
      row.ref_value = row[cell._id];
      row.rowName = 'ref_value';
      handleRowItemChanged(row);
    });
    const cmp = {
      int: (r, k     ) => classes[['compareEqual', 'compareNotEqual'][+!( intents[r.ref]?.name == r[k] )]],
      ent: (r, k, sfx) => classes[['compareEqual', 'compareNotEqual'][+!( r['ref'+ sfx] == r[k+sfx] )]],
      asr: (r, k     ) => classes[['compareEqual', 'compareNotEqual'][+!( r['ref'     ] == r[k    ] )]],
    }[viewTab];
    const cmp_old_new = (r, sfx='') => r['new'+ sfx] == r['old'+ sfx] ? classes.compareOldNewEqual : '';
    rows.forEach(r => {
      r.__classes = {
        old: cmp(r, 'old') +' '+ cmp_old_new(r),
        new: cmp(r, 'new') +' '+ cmp_old_new(r),
        old_slot:  cmp(r, 'old', '_slot')  +' '+ cmp_old_new(r, '_slot'),
        new_slot:  cmp(r, 'new', '_slot')  +' '+ cmp_old_new(r, '_slot'),
        old_value: cmp(r, 'old', '_value') +' '+ cmp_old_new(r, '_value'),
        new_value: cmp(r, 'new', '_value') +' '+ cmp_old_new(r, '_value'),
      };
      if (viewTab == 'asr')
        r.__diffs = {
          old: DMP.diff_main(r['ref'], r['old'] || ''),
          new: DMP.diff_main(r['ref'], r['new'] || ''),
        };
    });
    if (viewTab == 'ent')
      rows.filter(r => r.ref_slot).forEach(r => {
        const values = spareSlotValues[r.ref_slot]?.[0]; // 0 means 'for first entity only'
        if (values)
          r.__comboData = {
            ref_value: values.map(value => ({ value, label: value, title: 'slot name: '+ r.ref_slot })),
          };
        else
          r.__customTextField = {
            ref_value: true,
          };
      });
    if (viewTab == 'asr') {
      const fcp = (a,b) => { let s='', i=0; while (i<a.length && i<b.length && a[i] == b[i]) s+=a[i++]; return s } 
      const _p = rows.map(r => (r.audio || '').trim()).filter(Boolean);
      const prefix = _p.length ? _p.reduce((a,s) => fcp(a,s)) : null;
      prefix && rows.forEach(r => { r.audio = r.audio.replace(prefix, '') });
    }
    const csvname = 'details__'+ testCompareName(testmodel_1, false, false)
      + (testmodel_2 ? '__vs__'+ testCompareName(testmodel_2, false, false) : '') +'.csv';
    return (
      <EnhancedTable
        id={DIFF_VIEW_ID}
        headCells={headCells}
        rows={rows}
        viewCell={{
          old:        viewIntent,     new: viewIntent,
          old_slot:   viewSlot,       new_slot: viewSlot,
          old_value:  viewSlotValue,  new_value: viewSlotValue,
        }}
        toolBarName={dataset.name}
        handleRowItemChanged={handleRowItemChanged}
        customHeaderButtons={diffViewCustomHeader}
        fetchRows={fetchRows}
        pagingListInfo={pagingListInfo}
        downloadCSVName={getRunConfigOption('enableTestResultsCSVDownload') ? csvname : null}
      />
    );
  };

  const diff = () => (
    <div style={{display: 'flex'}}>
      <div>
        <IconButton
          Icon={ArrowBackIcon}
          title={t(`tests.back_to_summary`)}
          style={{left: -15}}
          onClick={() => setShowDiff(false)}
        />
      </div>
      {_diff()}
    </div>
  );

  const show_asr_stat = is_csv => {
    if (!data || typeof data != 'string' /* old asr stat from NS batches was {} */)
      return null;
    const lines = data.split('\n');
    const headCells = lines[0].split('\t').map(h => ({ _id: h, label: h }));
    const rows = [lines[1].split('\t').reduce((a,v,i) => { a[headCells[i]._id] = v; return a }, {})];
    const [asr_summary_header, ...asr_summary_report] = testmodel_1?.data?.summary || [null];

    if (is_csv)
      return ('ASR SUMMARY\n\n\n'+ dataset.name +'\n\n\n'
              + data +'\n\n\n'
              + (asr_summary_header ? (asr_summary_header +'\n\n' + asr_summary_report.join('\n')) : ''));

    return (<>
      <EnhancedTable
        id='asr_stat'
        headCells={headCells}
        rows={rows}
        toolBarName={dataset.name}
        height={100} // TODO: auto
        showPagination={false}
      />
      {asr_summary_header ? (
        <>
          <FormLabel
            style={{ marginTop: 30, marginBottom: 30, color: 'black' }}
            component="legend"
          >
            {asr_summary_header}
          </FormLabel>
          <TextField
            fullWidth
            multiline
            rowsMax="30"
            value={asr_summary_report.join('\n')}
            variant="outlined"
            InputProps={{ readOnly: true, style: { fontFamily: 'monospace' } }}
          />
        </>
      ) : null}
    </>);
  };

  const asr_diff = () => {
    const obj_size = o => Object.keys(o).length;
    const maxlen = rows.map(obj_size).reduce((a,x) => Math.max(a,x),0);
    const max_row = rows.find(r => obj_size(r) == maxlen) || {0:0,1:0,2:0,3:0,4:0};
    const key = i => i + ASR_KEY_MARK; // Warning: same as in BE
    const headCells = Object.keys(max_row)
      .map((_,i) => ({ _id: key(i), label: 1||i<2? '' : i-1, sortable: false }))
      .concat({ _id: maxlen, label: 'C_S_D_I', sortable: false, stick: 'right' });
    const empty = headCells.reduce((a,c) => { a[c._id] = ''; return a}, {});
    const grey_row = headCells.reduce((a,c) => { a[c._id] = classes.asrGrey; return a}, {});
    return (
      <EnhancedTable
        id='asr_diff'
        headCells={headCells}
        rows={rows.map(r => ({
          ...empty,
          ...r,
          [maxlen]: (r.scores || '').replace(/ /g, '_'),
          __classes: r[key(1)] == ''
            ? grey_row : { [key(0)]: classes.asrGrey, [key(1)]: classes.asrGrey, [maxlen]: classes.asrGrey },
        }))}
        toolBarName={dataset.name}
        // rowsPerPageOptions={[50, 100, 200]}
        customHeaderButtons={diffViewCustomHeader}
        fetchRows={fetchRows}
        pagingListInfo={pagingListInfo}
        styles={{ tableContainer: { overflowX: 'scroll'} }}
      />);
  };

  return (
    <Dialog
      open={true}
      fullScreen={true}
      disableEnforceFocus={true} // https://stackoverflow.com/a/54133377/6932612
      //scroll={scroll}
      onKeyUp={event => {
        if (event.key != 'Escape' || event.target.tagName == 'INPUT')
          return;
        if (showDiff)
          setShowDiff(false);
        else
          onClose();
      }}
    >
      <DialogTitle id="scroll-dialog-title" style={{ paddingTop: 0, paddingBottom: 0}}>
        <Toolbar disableGutters>
          {!is_asr ? (
            <Tabs
              value={TABS.indexOf(viewTab)}
              onChange={handleChange}
            >
              <Tab label={t("menu.intents")}/>
              <Tab label={t("menu.slots")} disabled={showDiff}/>
              <Tab label={t("tests.confusion_matrix")}/>
            </Tabs>
          ) : (
            <div>
              {t(showDiff ? "tests.asr_diffs" : "tests.asr_summary")}
            </div>
          )}
          <IconButton
            Icon={CloseIcon}
            onClick={onClose}
            style={{ display: 'flex', marginLeft: 'auto' /* == right-align */ }}
          />
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        {/*!is_asr &&*/
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
          {!data &&
          <div style={{ height: 40 }}>
            <Typography style={{ margin: 5 }}>{t("tests.no_data")}</Typography>
          </div>}
          {data && (showDiff || ['int','ent','asr'].includes(viewTab) &&
          <div style={{ display: 'flex', marginLeft: 'auto' /* == right-align */ }}>
              {viewTab != 'ent' &&
            <Button
              variant="outlined"
              disabled={{int: !testmodel_1.result?.length, ent: false}[viewTab]}
              size="small"
              style={{marginRight: 20}}
              onClick={() => setShowDiff(true)}
            >
              {t("tests.details")}
            </Button>
              }
            {(viewTab != 'asr' || showDiff) && ComparingModelSelect()}
            <IconButton
              Icon={GetAppIcon}
              title="csv" // same as here: N1
              style={{marginTop: 5, marginLeft: 20}}
              onClick={() => downloadData(summary(true), exportName('summary', viewTab == 'asr' ? 'txt' : undefined))}
            />
          </div>)}
        </div>}
        { viewTab != 'cm' ? showDiff ? diff() : summary()
        : (testmodel_1.confusionMatrix || testmodel_1?.data?.cm
          ? <ShowMatrix tm={testmodel_1} exportHandler={exportConfMatrixAsCSV}/>
          : t('tests.cm_error_no_matrix')) }
      </DialogContent>
    </Dialog>
  );
}

function SetAllAsRefDialog({ onClose }) {
  const [changeAll, setChangeAll] = useState(false);
  const { t } = useTranslation();
  return (
    <ConfirmDialog
      title={ucfirst(t("tests.diff_set_all_as_ref"))}
      btnNameDisagree={t("common.cancel")}
      btnNameAgree={t("common.save")}
      closeModal={onClose(!changeAll)}
      content={
        <RadioGroup
          value={changeAll ? 'all' : 'page'}
          onChange={event => setChangeAll(event.target.value == 'all')}
        >
          <FormControlLabel value="page" control={<Radio/>} label={t("tests.diff_change_page")} />
          <FormControlLabel value="all"  control={<Radio/>} label={t("tests.diff_change_all")} />
        </RadioGroup>
      }
    />
  );
}
